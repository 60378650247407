import { isDefined } from "@clipboard-health/util-ts";
import { differenceInDays, differenceInWeeks, formatDistanceStrict } from "date-fns";
import pluralize from "pluralize";

import { APP_V2_APP_EVENTS } from "../lib";
import { logError } from "../lib/analytics";
import { UXCamSdk } from "../lib/UXCamSdk/UXCamSdk";
import { type RatingAggregates } from "./api/useGetRatingAggregates";

export function getRatingPercentage(ratingValue: number, ratingAggregates: RatingAggregates) {
  if (!ratingAggregates.numberOfRatings) {
    return 0;
  }

  const ratingDetails = ratingAggregates.ratingsBreakdown.find(
    ({ rating }) => rating === ratingValue
  );
  return Number(
    (((ratingDetails?.numberOfRatings ?? 0) * 100) / ratingAggregates.numberOfRatings).toFixed(1)
  );
}

export function formatDiffBetweenDates(laterDate: Date, earlierDate: Date) {
  if (differenceInDays(laterDate, earlierDate) > 29) {
    return formatDistanceStrict(laterDate, earlierDate);
  }

  return pluralize("week", differenceInWeeks(laterDate, earlierDate) || 1, true);
}

export function getPageCursor(cursorUrl: string): string | undefined {
  const search = cursorUrl.split("?")[1];
  if (!isDefined(search)) {
    return undefined;
  }

  const searchParams = new URLSearchParams(search);

  return searchParams.get("page[cursor]") ?? undefined;
}

/**
 * @deprecated This function is deprecated and will be removed when we migrate review summary dialog to a page.
 * We currently utilize this function to categorize the review summary dialog as a page within UX Cam.
 */
export async function tagScreenNameDeprecated(screenName: string): Promise<void> {
  try {
    UXCamSdk.tagScreenName(screenName);
  } catch (error) {
    logError(APP_V2_APP_EVENTS.REVIEWS_TAG_SCREEN_ERROR, { error });
  }
}
