import { Text, useModalState } from "@clipboard-health/ui-react";
import { Rating, Skeleton, Stack } from "@mui/material";
import { useToast } from "@src/appV2/lib";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { BLUE_03 } from "@src/appV2/lib/Theme/constants";
import { useGetRatingAggregates } from "@src/appV2/Reviews/api/useGetRatingAggregates";
import { RATING_AGGREGATE_EMPTY, REVIEWS_SUMMARY_SCREEN_NAME } from "@src/appV2/Reviews/constants";
import pluralize from "pluralize";

import { type WorkplaceDetails } from "../../types";
import { tagScreenNameDeprecated } from "../../utils";
import { ReviewsSummaryDialog } from "../Dialogs";

export interface RatingsSummaryWorkplaceDetailsProps {
  workPlaceDetails: WorkplaceDetails;
  screenName: string;
}

/**
 * @deprecated
 * FIXME: This component is not correct. It uses a Stack as if it were a button, which
 * fails to meet the following guidelines:
 * https://www.notion.so/BP-Interactive-UI-Elements-9afebb326122478ba3814086aca6e2fc
 * This component will need to be rewritten to meet the standards.
 */
export function DeprecatedRatingsSummaryWorkplaceDetails(
  props: RatingsSummaryWorkplaceDetailsProps
) {
  const { workPlaceDetails, screenName } = props;
  const reviewSummaryModalState = useModalState();
  const { showErrorToast } = useToast();

  const {
    data: ratingAggregates = RATING_AGGREGATE_EMPTY,
    isLoading,
    isError,
  } = useGetRatingAggregates(workPlaceDetails.userId, {
    onError: () => {
      showErrorToast("Something went wrong while loading ratings");
    },
  });

  if (isLoading) {
    return <Skeleton variant="rectangular" width="100%" height={50} />;
  }

  return (
    <>
      <Stack
        /**
         * @deprecated
         * FIXME: This component is not correct. It uses a Stack as if it were a button, which
         * fails to meet the following guidelines:
         * https://www.notion.so/BP-Interactive-UI-Elements-9afebb326122478ba3814086aca6e2fc
         * This component will need to be rewritten to meet the standards.
         * Remember also, `Button`'s cannot have `div` or `p` elements.
         */
        alignItems="center"
        role="button"
        aria-label="Rate Facility"
        sx={{ marginTop: 1, color: BLUE_03 }}
        onClick={async () => {
          if (!isError) {
            await tagScreenNameDeprecated(REVIEWS_SUMMARY_SCREEN_NAME);
            reviewSummaryModalState.openModal();
            logEvent(APP_V2_USER_EVENTS.WORKPLACE_REVIEWS_SCREEN_OPENED, {
              workplaceId: workPlaceDetails.userId,
            });
          }
        }}
      >
        <Rating
          readOnly
          value={ratingAggregates.avgRating}
          precision={0.25}
          size="large"
          sx={{ color: "inherit" }}
        />
        <Text variant="body2" color="inherit">
          {`View ${pluralize("rating", ratingAggregates.numberOfRatings, true)}`}
        </Text>
      </Stack>

      <ReviewsSummaryDialog
        modalState={reviewSummaryModalState}
        workPlaceDetails={workPlaceDetails}
        screenName={screenName}
      />
    </>
  );
}
