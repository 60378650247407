import { type UseModalState } from "@clipboard-health/ui-react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { DateCalendarField } from "@src/appV2/lib/DatePicker";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";

import { type WorkplaceDetails } from "../../types";

interface LastWorkedAtDialogProps {
  modalState: UseModalState;
  workplaceDetails: WorkplaceDetails;
  onSubmit: (lastWorkedShiftDate: Date) => void;
}

export function LastWorkedAtDialog(props: LastWorkedAtDialogProps) {
  const { modalState, workplaceDetails, onSubmit } = props;

  const formMethods = useForm<{ lastWorkedShiftDate: Date }>({
    defaultValues: {
      lastWorkedShiftDate: new Date(),
    },
    resolver: zodResolver(z.object({ lastWorkedShiftDate: z.date() })),
    mode: "onChange",
  });
  const { formState, handleSubmit } = formMethods;

  return (
    <Dialog open={modalState.modalIsOpen} PaperProps={{ sx: { margin: 1 } }}>
      <FormProvider {...formMethods}>
        <DialogTitle sx={{ marginTop: 1, padding: 3 }} textAlign="center">
          When did you last work at {workplaceDetails.name}
        </DialogTitle>
        <form
          onSubmit={handleSubmit((formData) => {
            onSubmit(formData.lastWorkedShiftDate);
          })}
        >
          <DialogContent>
            <DateCalendarField
              disableFuture
              name="lastWorkedShiftDate"
              openTo="month"
              views={["year", "month"]}
              sx={{
                "&.MuiDateCalendar-root": {
                  width: "100%",
                },
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              fullWidth
              variant="contained"
              type="submit"
              disabled={formState.isSubmitting || !formState.isValid}
            >
              Next
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
}
