import { isDefined } from "@clipboard-health/util-ts";
import { type InfiniteData, useQueryClient } from "@tanstack/react-query";

import {
  getPaginatedWorkplaceCommentsQueryKey,
  type PaginatedWorkplaceCommentsParams,
} from "../api/usePaginatedWorkplaceComments";
import { type WorkplaceCommentsResponse } from "../api/useWorkplaceComments";

interface UseRefetchPaginatedCommentsProps {
  refetchComments: () => Promise<unknown>;
  workplaceCommentsParams: PaginatedWorkplaceCommentsParams;
}

export const useResetAndRefetchPaginatedComments = (props: UseRefetchPaginatedCommentsProps) => {
  const { refetchComments, workplaceCommentsParams } = props;
  const queryClient = useQueryClient();
  return {
    resetAndRefetchPages: async () => {
      queryClient.setQueryData<InfiniteData<WorkplaceCommentsResponse>>(
        getPaginatedWorkplaceCommentsQueryKey(workplaceCommentsParams),
        (previousData) => {
          if (!isDefined(previousData)) {
            return undefined;
          }

          return {
            pages: [previousData.pages[0]],
            pageParams: [previousData.pageParams[0]],
          };
        }
      );
      await refetchComments();
    },
  };
};
