export enum ReviewQuestionType {
  MULTIPLE_CHOICE = "MULTIPLE_CHOICE",
  THREAD = "THREAD",
  COMMENT = "COMMENT",
}

export enum Reaction {
  LIKE = "LIKE",
}

export enum QuestionTag {
  PARKING = "PARKING",
  PATIENTS_PER_NURSE = "PATIENTS_PER_NURSE",
  SOFTWARE = "SOFTWARE",
  SUPPLIES = "SUPPLIES",
  ALLOTTED_FOR_HYGIENE = "ALLOTTED_FOR_HYGIENE",
  DENTAL_ASSISTANT = "DENTAL_ASSISTANT",
  ULTRASONIC = "ULTRASONIC",
}

export interface WorkplaceDetails {
  userId: string;
  name: string;
  type: string;
}

export enum AggregateType {
  THREAD = "thread",
  MULTIPLE_CHOICE = "multipleChoice",
}
