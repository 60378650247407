import { type RatingAggregates } from "./api/useGetRatingAggregates";

export const RATING_AGGREGATE_EMPTY: RatingAggregates = {
  numberOfRatings: 0,
  avgRating: 0,
  ratingsBreakdown: [],
};

// Max length for review summary text is 85 because in most of the devices
// it doesn't span more than 2 lines
export const REVIEW_SUMMARY_MAX_LENGTH = 85;

export const PARKING_REVIEW_SUMMARY_PREFIX = "Where to park:";

export const OPEN_SHIFTS_ROUTE_V1 = "/home/openShifts";

export const REVIEWS_SUMMARY_SCREEN_NAME = "Workplace Reviews Summary";
