import { useModalState } from "@clipboard-health/ui-react";
import RateReviewIcon from "@mui/icons-material/RateReview";
import { Button } from "@mui/material";
import { useState } from "react";

import { type ReviewQuestion } from "../../api/useGetQuestions";
import { type WorkplaceDetails } from "../../types";
import { ReviewQuestionnaireDialog } from "../Dialogs/ReviewQuestionnaire";
import { LastWorkedAtDialog } from "./LastWorkedAtDialog";

interface WorkedHereBeforeButtonProps {
  workplaceDetails: WorkplaceDetails;
  questions: ReviewQuestion[];
  onSuccess: () => void;
}

export function WorkedHereBeforeButton(props: WorkedHereBeforeButtonProps) {
  const { workplaceDetails, questions, onSuccess } = props;
  const lastWorkedDatePickerModalState = useModalState();
  const reviewQuestionnaireModalState = useModalState();
  const [reviewDate, setReviewDate] = useState<Date | undefined>();

  if (questions.length === 0) {
    return null;
  }

  return (
    <>
      <Button
        startIcon={<RateReviewIcon />}
        variant="outlined"
        sx={{ width: "fit-content" }}
        onClick={() => {
          lastWorkedDatePickerModalState.openModal();
        }}
      >
        I&apos;ve worked here before
      </Button>
      <LastWorkedAtDialog
        modalState={lastWorkedDatePickerModalState}
        workplaceDetails={workplaceDetails}
        onSubmit={(lastWorkedShiftDate) => {
          setReviewDate(lastWorkedShiftDate);
          reviewQuestionnaireModalState.openModal();
          lastWorkedDatePickerModalState.closeModal();
        }}
      />
      <ReviewQuestionnaireDialog
        modalState={reviewQuestionnaireModalState}
        questions={questions}
        rating={0}
        workPlaceDetails={{ ...workplaceDetails, id: workplaceDetails.userId }}
        reviewDate={reviewDate}
        onSuccess={onSuccess}
      />
    </>
  );
}
