import { RootPaths } from "../App/paths";
import { REVIEWS_SUMMARY_SCREEN_NAME } from "./constants";

export const WorkplaceReviewsRouterPath = {
  REVIEWS_SUMMARY_PATH: `${RootPaths.HOME}/workplace-reviews/:workplaceId`,
  REVIEW_COMMENT_REPLIES_PATH: `${RootPaths.HOME}/workplace-reviews/:workplaceId/comments/:commentId`,
};

export const WorkplaceReviewsRouterPathInfo = {
  REVIEWS_SUMMARY_PATH: {
    name: REVIEWS_SUMMARY_SCREEN_NAME,
  },
  REVIEW_COMMENT_REPLIES_PATH: {
    name: "Workplace Review Comment Replies",
  },
};
